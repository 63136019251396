import { createStore } from 'redux'
import Cookies from 'js-cookie'

const initialState = {
  sidebarShow: true,
  autenticated: localStorage.getItem('autenticated'),
  token: localStorage.getItem('token'),
  username: localStorage.getItem('username'),
  admin: localStorage.getItem('admin'),
  // autenticated: Cookies.get('autenticated'),
  // token: Cookies.get('token'),
  // username: Cookies.get('username'),
  // admin: Cookies.get('admin'),
  asideShow: false,
  theme: 'default',
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}

const store = createStore(changeState)
export default store
